import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocalisedOpswatLink, i18nUtility } from '@mdc/services';
import { Col } from 'react-bootstrap';
import { InternalLink } from '@mdc/ui';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const FooterNavigation = () => {
    const { t, ready } = useTranslation();
    const [researchLink, setReasearchLink] = useState('https://www.opswat.com/research-center');
    const [productsLink, setProductsLink] = useState('https://www.opswat.com/products/metadefender/cloud');
    const [opswatLink, setOpswatLink] = useState('https://www.opswat.com');

    useEffect(() => {
        setReasearchLink(getLocalisedOpswatLink(researchLink));
        setProductsLink(getLocalisedOpswatLink(productsLink));
        setOpswatLink(getLocalisedOpswatLink(opswatLink));
    }, [t, i18nUtility.lookupLang()]);

    const reports = useMemo(() => <ul id='reports_footer'>
        <li className='group-header'>
            {t('Reports')}
        </li>
        <li>
            <OutboundLink
                href={researchLink}
                target='_blank'
                rel='noopener noreferrer'
            >
                {t('Overview')}
            </OutboundLink>
        </li>
        <li>
            <InternalLink
                to='/leaderboard'
            >{t('Leaderboard')}</InternalLink>
        </li>
    </ul>, [t, researchLink]);

    const resources = useMemo(() => <ul id='resources_footer'>
        <li className='group-header'>
            {t('Resources')}
        </li>
        <li>
            <OutboundLink
                href={productsLink}
                target='_blank'
                rel='noopener noreferrer'
            >
                {t('Product Description')}
            </OutboundLink>
        </li>
        <li>
            <OutboundLink
                href='https://docs.opswat.com/mdcloud'
                rel='noopener noreferrer'
                target='_blank'
            >
                {t('APIs')}
            </OutboundLink>
        </li>
        <li>
            <InternalLink
                to='/vulnerabilities'
            >{t('Supported CVE list')}</InternalLink>
        </li>
        <li>
            <OutboundLink
                href='https://trust.opswat.com/'
                rel='noopener noreferrer'
                target='_blank'
            >
                {t('Service Status')}
            </OutboundLink>
        </li>
        <li>
            <OutboundLink
                href='https://chromewebstore.google.com/detail/opswat-file-security-for/fjampemfhdfmangifafmianhokmpjbcj'
                rel='noopener noreferrer'
                target='_blank'
            >
                {t('Chrome Extension')}
            </OutboundLink>
        </li>
        <li>
            <OutboundLink
                href='https://go.opswat.com/OPSWATCommunity/s/'
                rel='noopener noreferrer'
                target='_blank'
            >
                {t('Community Forum')}
            </OutboundLink>
        </li>
    </ul>, [t, productsLink]);

    const about = useMemo(() => <ul id='about_footer'>
        <li className='group-header'>
            {t('About')}
        </li>
        <li>
            <OutboundLink
                href={`${opswatLink}/blog`}
                rel='noopener noreferrer'
                target='_blank'
            >
                {t('OPSWAT Blog')}
            </OutboundLink>
        </li>
        <li>
            <OutboundLink
                href={`${opswatLink}/academy`}
                rel='noopener noreferrer'
                target='_blank'
            >
                {t('OPSWAT Academy')}
            </OutboundLink>
        </li>
        <li>
            <OutboundLink
                href={`${opswatLink}/contact`}
                id='footer-link-contact-us'
                rel='noopener noreferrer'
                target='_blank'
            >
                {t('Contact Us')}
            </OutboundLink>
        </li>
        <li>
            <InternalLink
                to='/licensing'
            >{t('Licensing')}</InternalLink>
        </li>
        <li>
            <OutboundLink
                href={`${opswatLink}/company/about`}
                rel='noopener noreferrer'
                target='_blank'
            >
                {t('Company')}
            </OutboundLink>
        </li>
        <li>
            <InternalLink
                to="/store">
                {t('Store')}
            </InternalLink>
        </li>
    </ul>, [t, opswatLink]);

    if (!ready) {
        return null;
    }

    return (
        <>
            <Col
                className='d-sm-block d-md-block d-lg-block d-xl-block group'
                lg={2}
                md={4}
                sm={4}
            >
                {reports}
            </Col>
            <Col
                className='d-sm-block d-md-block d-lg-block d-xl-block group'
                lg={2}
                md={4}
                sm={4}
            >
                {resources}
            </Col>
            <Col
                className='d-sm-block d-md-block d-lg-block d-xl-block group'
                lg={2}
                md={4}
                sm={4}
            >
                {about}
            </Col>
        </>
    );
};

export default FooterNavigation;
